<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0"
      ><v-row align-content="center" justify="center" class="mx-0">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="3">
            <v-card-title>
              <span class="mb_max_width">Notifications</span>
              <v-spacer class="mb_hidden"></v-spacer>
             
                 

       
  <div class="text-center" v-bind="attrs"
                    v-on="on">
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    
                    large
                    color="primary"
                    >mdi-download-circle</v-icon
                  >
      </template>
      <v-list>
        <v-list-item
        @click="generate_report"
        >
        <v-list-item-icon><v-icon color="primary">mdi-gender-male-female</v-icon></v-list-item-icon>
          <v-list-item-title >Estrus </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  
              <div class="table_search_field mb_max_width">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="pt-0 mt-0 assign_select assign_select_v font_400 rounded-lg"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :server-items-length="notification_count"
                :headers="headers_general"
                :items="notifications"
                :items-per-page="100"
                class="elevation-1 livestock_table"
                hide-default-footer
                item-key="id"
                :options.sync="options"
                :sort-desc="true"
                no-data-text="No data available"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <div class="oc_table_header">
                    <v-tabs
                      class="table_tabs animal_tabs pt-1"
                      v-model="selected_table"
                    >
                      <v-tab>All</v-tab>
                      <v-tab>
                        <v-icon class="ml-4" color="oc_green"
                          >mdi-information-outline</v-icon
                        ></v-tab
                      >
                      <v-tab>
                        <v-icon class="ml-4" color="oc_orange"
                          >mdi-shield-alert-outline</v-icon
                        ></v-tab
                      >
                      <v-tab>
                        <v-icon class="ml-4" color="oc_red"
                          >mdi-alert-outline</v-icon
                        ></v-tab
                      >
                    </v-tabs>
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </div>
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden">
                    <td></td>

                    <td></td>
                    <td class="select_width align_left pl-2">
                      <v-menu
                        v-model="datetime_menu"
                        :close-on-content-click="false"
                        open-on-hover
                        offset-overflow
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div style="width: 180px" class="">
                            <v-text-field
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              append-icon="mdi-calendar"
                              class="mr-2 assign_select assign_select_v font_400"
                              single-line
                              hide-details
                              dense
                              height="32"
                              full-width
                              outlined
                              readonly
                              rounded
                              v-model="date"
                              :label="date ? date : 'Date'"
                            >
                            </v-text-field>
                          </div>
                        </template>

                        <v-card height="316" width="280">
                          <v-row>
                            <v-col>
                              <v-date-picker
                                v-model="date"
                                flat
                                no-title
                                id="video_dp"
                                event-color="#ff9900"
                                :max="todays_date"
                              ></v-date-picker>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                    </td>
                    <td class="select_width align_left pl-2">
                      <v-select
                        class="assign_select"
                        :items="camera_names"
                        v-model="selected_camera"
                        dense
                        height="32"
                        outlined
                        rounded
                        hide-details
                      ></v-select>
                    </td>
                    <td></td>

                    <td></td>

                    <td></td>
                    <td></td>
                  </tr>
                </template>

                <template v-slot:[`item.level`]="{ item }">
                  <v-icon
                    v-if="item.level == 'NOTICE'"
                    class="ml-4"
                    color="oc_green"
                    >mdi-information-outline</v-icon
                  >
                  <v-icon
                    v-else-if="item.level == 'WARNING'"
                    class="ml-4"
                    color="oc_orange"
                    >mdi-shield-alert-outline</v-icon
                  >
                  <v-icon
                    v-else-if="item.level == 'ALERT'"
                    class="ml-4"
                    color="oc_red"
                    >mdi-alert-outline</v-icon
                  >
                </template>
                <template v-slot:[`item.image`]="{ item, index }">
                  <v-img
                    class="ma-1 notification_img"
                    style="border-radius: 12px"
                    aspect-ratio="1.7"
                    :src="cookie_bucket + item.media_url"
                    @click="selected_image_index = index; open_image = true"
                  />
                </template>
                <template v-slot:[`item.camera_id`]="{ item }">
                  <span>{{ item.camera.name }}</span>
                </template>
                <template v-slot:[`item.datetime`]="{ item }">
                  <span>{{
                    item.datetime
                      .substring(0, item.datetime.length - 3)
                      .replace("T", " ")
                  }}</span>
                </template>
                <template v-slot:[`item.sent_email`]="{ item }">
                  <v-icon v-if="item.sent_datetime_twilio" color="oc_green"
                    >mdi-check-circle-outline</v-icon
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.sent_text`]="{ item }">
                  <v-icon v-if="item.sent_datetime_sendgrid" color="oc_green"
                    >mdi-check-circle-outline</v-icon
                  >
                  <span v-else>-</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row></v-container
    >

    <!-- <events></events> -->

    <v-dialog v-model="open_image" max-width="1400" :fullscreen="fullscreen_img"
      ><v-card v-if="notifications && notifications[selected_image_index]"
        ><v-card-title>
          <span
            >{{ get_date(notifications[selected_image_index].datetime) }}
          </span>
          <v-spacer></v-spacer>
          <span
            @click="nav_to_table(notifications[selected_image_index].camera_id)"
            class="mr-4"
            style="
              display: block;
              color: grey;
              font-size: 18px;
              margin-bottom: -2px;
              cursor: pointer;
            "
            ><v-icon>mdi-cctv</v-icon>
            {{ cameras_object[notifications[selected_image_index].camera_id] }}
          </span>

          <span style="float: right">
            <v-icon
              :disabled="selected_image_index == 0"
              @click="selected_image_index -= 1"
              class="mr-1"
              >mdi-arrow-left</v-icon
            ><v-icon
              :disabled="selected_image_index == notifications.length - 1"
              @click="selected_image_index += 1"
              class="ml-1"
              >mdi-arrow-right</v-icon
            ></span
          >
          <!-- <v-icon
            class="ml-2 mt-1"
            large
            v-if="fullscreen_img"
            @click="fullscreen_img = false"
            >mdi-fullscreen-exit</v-icon> -->
          </v-card-title
        ><v-card-text>
          <div style="position: relative">
            <image-inf
              :img_src="[
                [{image_index: selected_image_index, analytics : notifications[selected_image_index]}],
                cookie_bucket +
                  notifications[selected_image_index].media_url.replace(
                    '_annotated',
                    ''
                  ),
              ]"
            />

            <!-- <span
              id="fullscreen_notification_img"
              @click="fullscreen_img = true"
              ><v-icon style="height: 100%; width: 100%" large
                >mdi-fullscreen</v-icon
              ></span
            >
            <span id="download_notification_img">
              <a
                :href="
                  cookie_bucket +
                  notifications[selected_image_index].media_url.replace('_annotated', '')
                "
                style="height: 100%; width: 100%"
                download
              >
                <v-icon style="height: 100%; width: 100%">mdi-download</v-icon>
              </a></span
            > -->
          </div>
        </v-card-text>
      </v-card></v-dialog
    >
    <estrus_pdf_report />
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";
import ImageInf from "@/components/ImageInf.vue";
import estrus_pdf_report from '../components/pdf_reports/estrus_pdf_report.vue';
import { eventBus } from "../main.js";

export default {
  components: {
    navbar,
    sidenav,
    ImageInf,
    estrus_pdf_report
    //events,
  },
  props: ["date_filter"],
  data() {
    return {
      search: "",
      loading: true,
      date: null,
      todays_date: moment().format("YYYY-MM-DD"),

      datetime_menu: false,

      selected_date: false,
      selected_camera: "All",

      selected_table: 0,

      options: { sortBy: ["datetime"], sortDesc: [true], limit: 10, offset: 0 },

      level: [null, "INFO", "WARNING", "ALERT"],

      fullscreen_img: false,
      open_image: false,

      selected_image_index: false,
    };
  },
  methods: {
    generate_report() {
      eventBus.$emit("generate_estrus_report");
    },
    nav_to_table(device_id) {
      this.$router.push({
        name: "videos",
        params: {
          cameraname: device_id,
        },
      });
    },
    get_date(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD hh:mm A");
    },
    lu_age(date) {
      return moment().diff(moment(date, "YYYY-MM-DD"), "days") + " days";
    },
    add_dash(input) {
      if (
        input == "" ||
        input == null ||
        input == "null" ||
        input == "0" ||
        input == "N/A"
      ) {
        return "-";
      } else {
        return input;
      }
    },

    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
  },

  computed: {
    cameras_object() {
      let cams = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cams[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cams;
    },
    notification_count() {
      return this.$store.getters.getNotificationCount;
    },
    timezeone_offset() {
      if (this.$store.getters.getSites) {
        return this.$store.getters.getSites[0];
      }
      return { site_timezone: "UTC" };
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      if (this.customer_id) {
        return {
          sort: this.options.sortBy ? this.options.sortBy[0] : "datetime",
          sort_direction: this.sort_direction,
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          search: this.search,
          date: this.selected_date,
          filter: {
            level: this.level[this.selected_table],
            camera: this.selected_camera,
          },
        };
      }
      return undefined;
    },
    camera_names() {
      var cams = ["All"];
      for (const i in this.$store.getters.getCameraObjects) {
        cams.push({
          text: this.$store.getters.getCameraObjects[i].name,
          value: this.$store.getters.getCameraObjects[i].id,
        });
      }
      return cams;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },

    headers_general() {
      return [
        {
          text: "Level",
          value: "level",
          width: "60px",
          sortable: false,
        },
        { text: "", value: "image", width: "200px" },
        {
          text: "Time",
          value: "datetime",
          width: "180px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Camera Name",
          value: "camera_id",
          width: "180px",
          cellClass: "no_edit",
        },
        // { text: "Camera ID", value: "camera.id", width: "180px" },
        {
          text: "Title",
          value: "title",
          width: "180px",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Email",
          value: "sent_email",
          width: "90px",
        },
        {
          text: "TXT",
          value: "sent_text",
          width: "90px",
          sortable: false,
        },
      ];
    },

    notifications() {
      return this.$store.getters.getNotifications
        ? this.$store.getters.getNotifications
        : [];
    },

    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },
  created() {
    if (this.date_filter != "all") {
      this.date = this.date_filter;
    }
  },
  watch: {
    open_image() {
      if (!this.open_image) {
        this.selected_image_index = false;
      }
    },
    date() {
      var selected_date = moment(this.date, "YYYY-MM-DDTHH")
        .tz(this.timezeone_offset ? this.timezeone_offset.timezone : "UTC")
        .format("YYYY-MM-DDTHH");
      if (selected_date.slice(8, 10) != this.date.slice(8, 10)) {
        selected_date = moment(selected_date, "YYYY-MM-DDTHH")
          .add(1, "days")
          .format("YYYY-MM-DDTHH");
      }
      this.selected_date = selected_date;
    },

    query_params() {
      if (this.query_params) {
        this.$store.dispatch("DDB_GET_NOTIFICATIONS", this.query_params);
      }
      
    },

    customer_id() {
      this.$store.dispatch("DDB_GET_CAMERAS")
    },

    dialog() {
      if (this.dialog == false) {
        this.owner_name = false;
        this.create_new = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.notification_img:hover {
  transform: scale(1.4);
  z-index: 500;
}
</style>
